
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import Vivus from "vivus";

export default defineComponent({
  setup() {
    const state = reactive({
      svg: null as HTMLElement | null,
    });

    onMounted(() => {
      if (!state.svg) return;

      const vivus = new Vivus(state.svg, {
        type: "scenario-sync",
        duration: 4,
        start: "manual",
        animTimingFunction: Vivus.EASE,
      });

      vivus.play();
    });
    return {
      ...toRefs(state),
    };
  },
});
