
import {
  defineComponent,
  reactive,
  toRefs,
  watch,
  nextTick,
  PropType,
} from "vue";
import YoutubeItem from "@/components/YoutubeItem.vue";
import InfiniteLoader from "@/components/InfiniteLoader.vue";
import { Video, Tag } from "@/types/graphcms";

export default defineComponent({
  components: {
    YoutubeItem,
    InfiniteLoader,
  },
  props: {
    videos: {
      type: Array as PropType<Video[]>,
      required: true,
    },
    tags: {
      type: Array as PropType<Tag[]>,
      required: true,
    },
  },
  setup(props) {
    const first = 4;
    const state = reactive({
      skip: 0,
      isLoadCompleted: false,
      pickupTags: props.tags.filter((tag) => tag.pickup),
      selectedTag: null as Tag | null,
      videos: [] as Video[],
    });

    // TODO: あとで共通化
    const getVideosByTag = (tagId: string) => {
      if (!tagId) return props.videos;

      return props.videos.filter((video) => {
        const tagIds = video.tags.map((tag) => tag.id);

        return tagIds.includes(tagId);
      });
    };

    const appendVideos = () => {
      const videos = state.selectedTag
        ? getVideosByTag(state.selectedTag.id)
        : props.videos;
      const sliced = videos.slice(state.skip, state.skip + first);

      if (!sliced.length) {
        return (state.isLoadCompleted = true);
      }

      state.skip += first;
      state.videos = [...state.videos, ...sliced];
    };

    const clickPickupTag = (tag: Tag) => {
      state.selectedTag = tag;
    };

    watch(
      () => state.selectedTag,
      () => {
        state.skip = 0;
        state.isLoadCompleted = false;
        state.videos = [];

        nextTick(() => {
          appendVideos();
        });
      },
      {
        immediate: true,
      }
    );

    return {
      ...toRefs(state),
      appendVideos,
      clickPickupTag,
    };
  },
});
