<template>
  <div>
    <h1 class="text-center px-3 pt-4">
      <SvgEditorsSelection class="w-full" />
    </h1>
    <p class="font-bold text-center text-xs mb-4">
      編集部が厳選したスキー＆スノボ動画を毎日更新！
    </p>
    <div v-for="video in videos" :key="video.videoId" class="mb-7">
      <YoutubeItem :video="video" />
      <EditorsComment :video="video" />
    </div>

    <InfiniteLoader
      :handler="appendVideos"
      :is-load-completed="isLoadCompleted"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, PropType } from "vue";
import SvgEditorsSelection from "@/components/svg/EditorsSelection.vue";
import YoutubeItem from "@/components/YoutubeItem.vue";
import EditorsComment from "@/components/EditorsComment.vue";
import InfiniteLoader from "@/components/InfiniteLoader.vue";
import { Video } from "@/types/graphcms";

export default defineComponent({
  components: {
    SvgEditorsSelection,
    YoutubeItem,
    EditorsComment,
    InfiniteLoader,
  },
  props: {
    editorsSelection: {
      type: Array as PropType<Video[]>,
      required: true,
    },
  },
  setup(props) {
    const first = 2;
    const state = reactive({
      skip: 0,
      isLoadCompleted: false,
      videos: [] as Video[],
    });

    const appendVideos = () => {
      const videos = props.editorsSelection.slice(
        state.skip,
        state.skip + first
      );

      if (!videos.length) {
        return (state.isLoadCompleted = true);
      }
      state.skip += first;
      state.videos = [...state.videos, ...videos];
    };

    appendVideos();

    return {
      ...toRefs(state),
      appendVideos,
    };
  },
  head: {
    title: "LABEL",
  },
});
</script>
