<template>
  <div
    class="splash-wrapper bg-snowgray-darkest fixed w-full h-full z-40 top-0 left-0 flex items-center justify-center"
  >
    <img
      src="/assets/images/common/fig_splash.png"
      class="w-4/6"
      alt=""
      @load="splashLoaded"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["splash-end"],
  setup(_, context) {
    const splashLoaded = () => {
      setTimeout(async () => {
        localStorage.setItem("alreadySplashed", "1");
        context.emit("splash-end");
      }, 3200);
    };

    return {
      splashLoaded,
    };
  },
});
</script>
