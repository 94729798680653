<template>
  <div>
    <div class="p-4 pb-7">
      <div class="grid grid-cols-2 gap-4">
        <div
          v-for="tag in pickupTags"
          :key="tag.id"
          class="bg-snowblue rounded px-4 py-3.5 text-sm font-bold"
          @click="clickPickupTag(tag)"
        >
          #{{ tag.name }}
        </div>
      </div>

      <div
        class="relative text-sm border border-snowblue rounded bg-snowgray-dark w-full mt-4 py-3 text-center select-style"
        :class="{ 'is-selected': selectedTag }"
      >
        <div v-if="selectedTag">#{{ selectedTag.name }}</div>
        <div v-else>ハッシュタグを選択</div>

        <select
          v-model="selectedTag"
          class="absolute left-0 top-0 w-full h-full opacity-0 bg-snowgray-dark"
        >
          <option v-for="tag in tags" :key="tag.id" :value="tag">
            #{{ tag.name }}
          </option>
        </select>
      </div>
    </div>

    <div v-for="video in videos" :key="video.videoId" class="mb-7">
      <YoutubeItem :video="video" />
    </div>
    <InfiniteLoader
      :handler="appendVideos"
      :is-load-completed="isLoadCompleted"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  toRefs,
  watch,
  nextTick,
  PropType,
} from "vue";
import YoutubeItem from "@/components/YoutubeItem.vue";
import InfiniteLoader from "@/components/InfiniteLoader.vue";
import { Video, Tag } from "@/types/graphcms";

export default defineComponent({
  components: {
    YoutubeItem,
    InfiniteLoader,
  },
  props: {
    videos: {
      type: Array as PropType<Video[]>,
      required: true,
    },
    tags: {
      type: Array as PropType<Tag[]>,
      required: true,
    },
  },
  setup(props) {
    const first = 4;
    const state = reactive({
      skip: 0,
      isLoadCompleted: false,
      pickupTags: props.tags.filter((tag) => tag.pickup),
      selectedTag: null as Tag | null,
      videos: [] as Video[],
    });

    // TODO: あとで共通化
    const getVideosByTag = (tagId: string) => {
      if (!tagId) return props.videos;

      return props.videos.filter((video) => {
        const tagIds = video.tags.map((tag) => tag.id);

        return tagIds.includes(tagId);
      });
    };

    const appendVideos = () => {
      const videos = state.selectedTag
        ? getVideosByTag(state.selectedTag.id)
        : props.videos;
      const sliced = videos.slice(state.skip, state.skip + first);

      if (!sliced.length) {
        return (state.isLoadCompleted = true);
      }

      state.skip += first;
      state.videos = [...state.videos, ...sliced];
    };

    const clickPickupTag = (tag: Tag) => {
      state.selectedTag = tag;
    };

    watch(
      () => state.selectedTag,
      () => {
        state.skip = 0;
        state.isLoadCompleted = false;
        state.videos = [];

        nextTick(() => {
          appendVideos();
        });
      },
      {
        immediate: true,
      }
    );

    return {
      ...toRefs(state),
      appendVideos,
      clickPickupTag,
    };
  },
});
</script>

<style lang="scss" scoped>
@mixin arrow($color: 316cff) {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="%23#{$color}" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/></svg>')
    no-repeat right 14px center / 12px;
}

.select-style {
  @include arrow;

  &.is-selected {
    @include arrow(fff);
    @apply bg-snowblue;
  }
}
</style>
