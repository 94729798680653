
import { defineComponent, reactive, toRefs, onMounted, computed } from "vue";
import { graphCMS } from "@/utils/graphCMS";
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import SplashAnime from "@/components/SplashAnime.vue";
import { Video, Tag, NewsCover } from "@/types/graphcms";

export default defineComponent({
  components: {
    TheHeader,
    TheFooter,
    SplashAnime,
  },
  setup() {
    const state = reactive({
      alreadySplashed: !!localStorage.getItem("alreadySplashed"),
      videos: [] as Video[],
      tags: [] as Tag[],
      newsCovers: [] as NewsCover[],
    });

    onMounted(async () => {
      const { getTags, getNewsCovers, getVideos } = graphCMS();

      state.tags = await getTags();
      state.newsCovers = await getNewsCovers();
      state.videos = await getVideos();
    });

    const editorsSelection = computed(() => {
      return state.videos.filter((video) => video.editorsSelection);
    });

    const onSplashEnd = () => {
      state.alreadySplashed = true;
    };

    return {
      ...toRefs(state),
      onSplashEnd,
      editorsSelection,
    };
  },
});
