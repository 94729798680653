<template>
  <div ref="root" class="py-4">
    <img
      v-if="isLoading"
      class="mx-auto"
      src="/assets/images/common/ico_loading.svg"
      alt=""
    />
    <p
      v-if="isLoadCompleted && !isLoading"
      class="text-center text-gray-300 text-xs"
    >
      End of content.
    </p>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  onUnmounted,
  watch,
  nextTick,
  PropType,
} from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  props: {
    handler: {
      type: Function as PropType<{ (): boolean }>,
      default: null,
    },
    isLoadCompleted: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const route = useRoute();
    const state = reactive({
      root: null as HTMLElement | null,
      isLoading: false,
    });

    const sleep = (time: number) =>
      new Promise((resolve) => setTimeout(resolve, time));

    const handleScroll = async () => {
      // 次のいずれかの場合は何もしない
      // - rootエレメントがない
      // - isLoadCompleted状態
      // - このエレメントがdisplay noneである
      if (!state.root || props.isLoadCompleted || !state.root.offsetParent)
        return;

      const rect = state.root.getBoundingClientRect();
      const isIntoView = rect.bottom < window.innerHeight;

      if (isIntoView && !state.isLoading) {
        state.isLoading = true;
        props.handler();
        await sleep(1000);
        state.isLoading = false;
      }
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    watch(
      () => state.isLoading,
      async (isLoading) => {
        if (!isLoading) {
          await nextTick();
          handleScroll();
        }
      }
    );

    watch(
      () => route.hash,
      async () => {
        await nextTick();
        handleScroll();
      }
    );

    return {
      ...toRefs(state),
    };
  },
});
</script>
