
import { defineComponent, PropType } from "vue";
import YoutubePlayer from "@/components/YoutubePlayer.vue";
import { Video } from "@/types/graphcms";

export default defineComponent({
  components: {
    YoutubePlayer,
  },
  props: {
    video: {
      type: Object as PropType<Video>,
      required: true,
    },
  },
});
