<template>
  <div>
    <transition name="fade">
      <SplashAnime v-if="!alreadySplashed" @splash-end="onSplashEnd" />
    </transition>
    <div
      v-if="alreadySplashed"
      class="flex flex-col max-w-3xl mx-auto text-white min-h-screen"
    >
      <TheHeader />
      <router-view v-if="videos.length" v-slot="{ Component }">
        <transition name="fade">
          <keep-alive>
            <component
              :is="Component"
              :videos="videos"
              :editors-selection="editorsSelection"
              :tags="tags"
              :news-covers="newsCovers"
              class="bg-snowgray-dark flex-grow"
            />
          </keep-alive>
        </transition>
      </router-view>
      <TheFooter />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, onMounted, computed } from "vue";
import { graphCMS } from "@/utils/graphCMS";
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import SplashAnime from "@/components/SplashAnime.vue";
import { Video, Tag, NewsCover } from "@/types/graphcms";

export default defineComponent({
  components: {
    TheHeader,
    TheFooter,
    SplashAnime,
  },
  setup() {
    const state = reactive({
      alreadySplashed: !!localStorage.getItem("alreadySplashed"),
      videos: [] as Video[],
      tags: [] as Tag[],
      newsCovers: [] as NewsCover[],
    });

    onMounted(async () => {
      const { getTags, getNewsCovers, getVideos } = graphCMS();

      state.tags = await getTags();
      state.newsCovers = await getNewsCovers();
      state.videos = await getVideos();
    });

    const editorsSelection = computed(() => {
      return state.videos.filter((video) => video.editorsSelection);
    });

    const onSplashEnd = () => {
      state.alreadySplashed = true;
    };

    return {
      ...toRefs(state),
      onSplashEnd,
      editorsSelection,
    };
  },
});
</script>
