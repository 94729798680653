import axios from "axios";

export type NewsItem = {
  title: string;
  published: number;
  origin: string;
  url: string;
};

export const repository = () => {
  const getNews = async (): Promise<NewsItem[]> => {
    const endpoint = process.env.VUE_APP_NEWS_ENDPOINT;

    if (!endpoint) return [];

    return await axios.get(endpoint).then((res) => res.data.items);
  };

  return {
    getNews,
  };
};
