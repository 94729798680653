
import { defineComponent, reactive, toRefs, computed, PropType } from "vue";
import InfiniteLoader from "@/components/InfiniteLoader.vue";
import YoutubeItem from "@/components/YoutubeItem.vue";
import { Video, Tag } from "@/types/graphcms";

export default defineComponent({
  components: {
    YoutubeItem,
    InfiniteLoader,
  },
  props: {
    videos: {
      type: Array as PropType<Video[]>,
      required: true,
    },
    tags: {
      type: Array as PropType<Tag[]>,
      required: true,
    },
  },
  setup(props) {
    const first = 4;
    const state = reactive({
      skip: 0,
      isLoadCompleted: false,
      videos: [] as Video[],
    });

    const todaysTag = computed(() => {
      const date = new Date().getDate();
      const tags = props.tags.filter((tag) => tag.forTodaysTag);

      return tags[date % tags.length];
    });

    // TODO: あとで共通化
    const getVideosByTag = (tagId: string) => {
      if (!tagId) return props.videos;

      return props.videos.filter((video) => {
        const tagIds = video.tags.map((tag) => tag.id);

        return tagIds.includes(tagId);
      });
    };

    const appendVideos = () => {
      const videos = getVideosByTag(todaysTag.value.id).slice(
        state.skip,
        state.skip + first
      );

      if (!videos.length) {
        return (state.isLoadCompleted = true);
      }

      state.skip += first;
      state.videos = [...state.videos, ...videos];
    };

    const hex2rgb = (hex: string) => {
      const color = hex.replace("#", "");
      const r = parseInt(color.substring(0, 2), 16);
      const g = parseInt(color.substring(2, 4), 16);
      const b = parseInt(color.substring(4, 6), 16);
      return `${r}, ${g}, ${b}`;
    };

    const gradientStyle = computed(() => {
      const color = todaysTag.value.tagColor?.hex || "#316cff";

      if (!todaysTag.value.image) return `background: ${color}`;

      return `
          background: linear-gradient(
            60deg,
            rgba(${hex2rgb(color)}, 1) 55%,
            rgba(${hex2rgb(color)}, 0.7) 75%,
            rgba(${hex2rgb(color)}, 0.7) 100%
          );`;
    });

    const bgStyle = computed(() => {
      const bg = todaysTag.value.image?.url || "";

      return `
        filter: grayscale(1);
        background-size: cover;
        background-image: url(${bg});
      `;
    });

    appendVideos();

    return {
      ...toRefs(state),
      appendVideos,
      gradientStyle,
      todaysTag,
      bgStyle,
    };
  },
});
