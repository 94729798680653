<template>
  <svg ref="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297 104">
    <image
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xlink:href="/assets/images/fig_editors-selection-line.svg"
      width="297"
      height="104"
      mask="url(#clipmask-line)"
    ></image>
    <image
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xlink:href="/assets/images/fig_editors-selection-text.svg"
      width="297"
      height="104"
      mask="url(#clipmask-text)"
    ></image>
    <mask id="clipmask-text" maskUnits="objectBoundingBox">
      <g id="Shape 2">
        <path
          class="shp0"
          d="M59.8 22.02C65.19 16.64 73.48 16.82 80.8 16.82C81.67 16.82 83.13 16 83.4 16.82C84.46 20.01 80.8 20.96 80.8 23.42"
        />
        <path class="shp0" d="M69.2 22.02C69.2 32.34 62.8 41.99 62.8 52" />
        <path class="shp0" d="M58.8 39.22C60.36 37.66 78.74 30.57 82.4 34.22" />
        <path class="shp0" d="M64.4 52C64.55 52 81.2 49.3 81.2 48.82" />
        <path
          class="shp0"
          d="M94.8 21.02C93.17 29.16 92.73 37.72 90.8 45.42C90.67 45.94 90.57 47.73 91.2 47.82C102.61 49.45 115.48 25.52 108.8 18.62C104.69 14.37 89 12.1 89 21.62"
        />
        <path class="shp0" d="M119.4 15.62C116.64 26.65 117 36.39 117 47.22" />
        <path class="shp0" d="M126 18.02C126 11.05 153.31 10.82 158.8 10.82" />
        <path class="shp0" d="M142.8 13.62C138.06 20.74 138 36.05 138 44.62" />
        <path
          class="shp0"
          d="M163.8 27.62C163.8 25.17 162.2 22.38 162.2 20.22C162.2 2.24 174.61 15.28 169.2 34.22C168.36 37.15 165.77 40.3 162.4 39.62C150.31 37.2 161.2 17.47 161.2 12.42"
        />
        <path
          class="shp0"
          d="M185.6 11.82C185.6 20.49 181.8 30.21 181.8 38.82"
        />
        <path
          class="shp0"
          d="M178 14.22C180.49 6.76 194.51 3.83 198.2 11.22C201.33 17.49 189.37 26.09 187 30.82C185.4 34.02 196.49 41.11 198.2 42.82"
        />
        <path class="shp0" d="M209 2.22C209 5.65 207.8 9.26 207.8 12.42" />
        <path
          class="shp0"
          d="M232.2 10.22C232.2 8.53 235.86 6.36 235 4.22C231.18 -5.34 214.49 8.01 213.2 13.82C211.19 22.86 231.53 16.82 229.8 27.22C229.26 30.46 215.01 37.83 213.2 34.22C211.97 31.77 214.2 30.27 214.2 28.82"
        />
        <path
          class="shp0"
          d="M58.4 67.62C58.4 52.2 43.15 64.32 39 72.62C34.36 81.9 60.7 74.42 54.2 87.42C49.43 96.95 34.71 91.31 39.4 86.62"
        />
        <path class="shp0" d="M66.4 63.02C70.65 61.61 97.39 55.43 87 65.82" />
        <path class="shp0" d="M72.6 64.82C69.8 76.04 65 81.43 65 92.02" />
        <path class="shp0" d="M69.2 79.62C74.31 74.51 84.6 76.02 91.2 76.02" />
        <path class="shp0" d="M68.2 91.62C73.2 88.62 80.72 87.82 86.6 87.82" />
        <path
          class="shp0"
          d="M103 57.42C103 66.86 96.2 76.63 96.2 86.62C96.2 87.69 94.96 89.56 96 89.82C101.43 91.18 108.29 88.42 114 88.42"
        />
        <path
          class="shp0"
          d="M121.4 60.22C125.39 57.56 131.43 55.12 136.4 54.62C148.73 53.39 141.2 58.61 141.2 64.22"
        />
        <path class="shp0" d="M132.8 59.22C130.98 59.22 120 82.57 120 88.02" />
        <path
          class="shp0"
          d="M121 79.02C124.66 75.36 133.49 74.42 138.6 74.42"
        />
        <path
          class="shp0"
          d="M120.6 89.42C126.32 89.42 132.28 86.82 138.2 86.82"
        />
        <path
          class="shp0"
          d="M166 65.02C166 35.08 143.93 84.15 146.6 86.82C154.98 95.2 163.85 84.03 165.8 77.22C165.88 76.93 166.17 75.22 166.4 75.22"
        />
        <path
          class="shp0"
          d="M175 59.82C178.31 55.41 199.31 48.53 204.4 53.62"
        />
        <path
          class="shp0"
          d="M188.2 59.02C184.85 65.72 188.93 82.69 183.8 87.82"
        />
        <path class="shp0" d="M207.2 57.22C203.82 63.98 205 77.66 205 85.62" />
        <path
          class="shp0"
          d="M222.8 71.02C222.44 68.48 222.2 65.78 222.2 63.22C222.2 60.93 222.38 58.46 223.4 56.42C225.12 52.98 226.98 53.39 228.6 56.62C231.03 61.49 229.91 91.08 217.8 85.02C210.17 81.21 214.55 58.22 218 58.22"
        />
        <path
          class="shp0"
          d="M235.6 83.42C235.6 76.55 238.93 69.65 240.4 63.02C240.95 60.56 242.09 58.1 243 55.82C243.39 54.84 243.25 52.08 244 52.82C248.94 57.77 247.74 67.31 250.6 73.02C251.44 74.7 251.53 76.95 252 78.82C252.26 79.86 252.2 83.09 252.2 82.02C252.2 71 261.6 60.23 261.6 49.22"
        />
      </g>
    </mask>

    <mask id="clipmask-line" maskUnits="objectBoundingBox">
      <path class="shp1" d="M5 25L288 42" />
      <path class="shp1" d="M0 69L297 66" />
    </mask>
  </svg>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import Vivus from "vivus";

export default defineComponent({
  setup() {
    const state = reactive({
      svg: null as HTMLElement | null,
    });

    onMounted(() => {
      if (!state.svg) return;

      const vivus = new Vivus(state.svg, {
        type: "scenario-sync",
        duration: 4,
        start: "manual",
        animTimingFunction: Vivus.EASE,
      });

      vivus.play();
    });
    return {
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
.shp0 {
  stroke: #fff;
  stroke-width: 12;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.shp1 {
  stroke: #fff;
  stroke-width: 40;
}
</style>
