
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["splash-end"],
  setup(_, context) {
    const splashLoaded = () => {
      setTimeout(async () => {
        localStorage.setItem("alreadySplashed", "1");
        context.emit("splash-end");
      }, 3200);
    };

    return {
      splashLoaded,
    };
  },
});
