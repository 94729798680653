<template>
  <div>
    <div v-if="isLoading" class="mt-10">
      <img class="mx-auto" src="/assets/images/common/ico_loading.svg" alt="" />
    </div>
    <div v-for="item in newsItems" :key="item.origin">
      <a :href="item.url" class="flex mt-3 mb-6" target="_blank">
        <div class="w-32 h-20 flex-shrink-0 py-1">
          <div
            class="w-full h-full bg-cover bg-center"
            :style="`background-image: url(${getCoverImage(item.origin)})`"
          ></div>
        </div>
        <div class="px-3">
          <p class="text-sm font-black text-snowblue">
            {{ utc2jst(item.published) }}
          </p>
          <p class="text-xs mt-0.5 leading-relaxed">
            {{ item.title }}
          </p>
        </div>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, onMounted, PropType } from "vue";
import { repository, NewsItem } from "@/utils/repository";
import { NewsCover } from "@/types/graphcms";

export default defineComponent({
  props: {
    newsCovers: {
      type: Array as PropType<NewsCover[]>,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      isLoading: true,
      newsItems: [] as NewsItem[],
    });
    const { getNews } = repository();

    onMounted(async () => {
      state.newsItems = await getNews();
      state.isLoading = false;
    });

    const utc2jst = (utc: number) => {
      const d = new Date(utc * 1000);
      const month = ("0" + (d.getMonth() + 1)).slice(-2);
      const date = ("0" + d.getDate()).slice(-2);

      return `${d.getFullYear()}.${month}.${date}`;
    };

    const getCoverImage = (origin: string) => {
      const found = props.newsCovers.find((cover) =>
        origin.startsWith(cover.siteUrl)
      );

      if (!found || !found.coverImage)
        return "/assets/images/news/fig_default-cover.png";

      return found.coverImage.url;
    };

    return {
      ...toRefs(state),
      utc2jst,
      getCoverImage,
    };
  },
});
</script>
