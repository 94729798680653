<template>
  <div class="relative">
    <img
      v-if="isLoading"
      class="absolute inset-0 m-auto"
      src="/assets/images/common/ico_loading.svg"
    />
    <div class="player-wrapper relative" :class="{ 'is-loading': isLoading }">
      <div ref="playerRef" class="absolute w-full h-full"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import YTPlayer from "yt-player";

export default defineComponent({
  props: {
    videoId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      isLoading: true,
      playerRef: null as HTMLElement | null,
    });

    onMounted(() => {
      if (!state.playerRef) throw new Error("Faild get element");

      const player = new YTPlayer(state.playerRef);

      player.load(props.videoId);
      player.on("unstarted", () => {
        state.isLoading = false;
      });
    });

    return {
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
.player-wrapper {
  padding-bottom: percentage(9/16);
  transition: opacity 0.7s, filter 0.6s 0.3s;

  &.is-loading {
    @apply opacity-0;
    filter: grayscale(1);
  }
}
</style>
