
import { defineComponent, reactive, toRefs, onMounted, PropType } from "vue";
import { repository, NewsItem } from "@/utils/repository";
import { NewsCover } from "@/types/graphcms";

export default defineComponent({
  props: {
    newsCovers: {
      type: Array as PropType<NewsCover[]>,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      isLoading: true,
      newsItems: [] as NewsItem[],
    });
    const { getNews } = repository();

    onMounted(async () => {
      state.newsItems = await getNews();
      state.isLoading = false;
    });

    const utc2jst = (utc: number) => {
      const d = new Date(utc * 1000);
      const month = ("0" + (d.getMonth() + 1)).slice(-2);
      const date = ("0" + d.getDate()).slice(-2);

      return `${d.getFullYear()}.${month}.${date}`;
    };

    const getCoverImage = (origin: string) => {
      const found = props.newsCovers.find((cover) =>
        origin.startsWith(cover.siteUrl)
      );

      if (!found || !found.coverImage)
        return "/assets/images/news/fig_default-cover.png";

      return found.coverImage.url;
    };

    return {
      ...toRefs(state),
      utc2jst,
      getCoverImage,
    };
  },
});
