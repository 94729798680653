import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import home from "../views/home.vue";
import todaysTag from "../views/todays-tag.vue";
import filterByTag from "../views/filter-by-tag.vue";
import news from "../views/news.vue";

const meta = {
  title: "NEW SNOW MEDIA | 雪山が、もっと楽しくなる",
  description: "動画で情報を知る、新しい雪山メディア。もっと、楽しくなる。",
};

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: home,
    meta: { title: "" },
  },
  {
    path: "/todays-tag",
    name: "todays-tag",
    component: todaysTag,
  },
  {
    path: "/filter-by-tag",
    name: "filter-by-tag",
    component: filterByTag,
  },
  {
    path: "/news",
    name: "news",
    component: news,
  },
];

const router = createRouter({
  scrollBehavior: () => {
    return { top: 0 };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
