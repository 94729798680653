<template>
  <div class="sticky top-0 z-30 bg-snowgray-darkest">
    <h1>
      <router-link to="/">
        <img
          src="/assets/images/common/hdg_global-header.svg"
          alt="NEW SNOW MEDIA | 雪山が、もっと楽しくなる"
          class="w-full"
        />
      </router-link>
    </h1>
    <nav class="bg-snowgray-dark border-b border-snowgray overflow-x-auto py-2">
      <div class="inline-flex px-2.5">
        <TheHeaderLink to="/">SELECTION</TheHeaderLink>
        <TheHeaderLink to="/todays-tag">TODAY'S #</TheHeaderLink>
        <TheHeaderLink to="/filter-by-tag">FILTER BY #</TheHeaderLink>
        <TheHeaderLink to="/news">NEWS</TheHeaderLink>
      </div>
    </nav>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TheHeaderLink from "@/components/TheHeaderLink.vue";

export default defineComponent({
  components: {
    TheHeaderLink,
  },
});
</script>
