<template>
  <router-link
    class="block text-xs bg-snowgray rounded-full font-black px-3 py-1.5 mr-2 last:mr-0 whitespace-nowrap"
  >
    <slot />
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style lang="scss" scoped>
.router-link-active {
  @apply bg-gray-100;
  @apply text-black;
}
</style>
