
import { defineComponent, PropType } from "vue";
import { Video } from "@/types/graphcms";

export default defineComponent({
  props: {
    video: {
      type: Object as PropType<Video>,
      required: true,
    },
  },
});
