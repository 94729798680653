<template>
  <div>
    <YoutubePlayer :video-id="video.videoId" />
    <div class="text-xs leading-relaxed px-3 mt-2">
      <p>
        <a
          :href="`https://www.youtube.com/watch?v=${video.videoId}`"
          target="_blank"
          >{{ video.videoTitle }}</a
        >
      </p>

      <p v-if="video.channel" class="mt-1 text-snowgray-light">
        <a
          :href="`https://www.youtube.com/channel/${video.channel.channelId}`"
          target="_blank"
          >{{ video.channel.channelName }}</a
        >
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import YoutubePlayer from "@/components/YoutubePlayer.vue";
import { Video } from "@/types/graphcms";

export default defineComponent({
  components: {
    YoutubePlayer,
  },
  props: {
    video: {
      type: Object as PropType<Video>,
      required: true,
    },
  },
});
</script>
