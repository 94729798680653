
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import YTPlayer from "yt-player";

export default defineComponent({
  props: {
    videoId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      isLoading: true,
      playerRef: null as HTMLElement | null,
    });

    onMounted(() => {
      if (!state.playerRef) throw new Error("Faild get element");

      const player = new YTPlayer(state.playerRef);

      player.load(props.videoId);
      player.on("unstarted", () => {
        state.isLoading = false;
      });
    });

    return {
      ...toRefs(state),
    };
  },
});
