import { ApolloClient, InMemoryCache, gql } from "@apollo/client/core";
import { Video, Tag, NewsCover } from "@/types/graphcms";

export const graphCMS = () => {
  const client = new ApolloClient({
    uri: process.env.VUE_APP_GPRAH_CMS_ENDPOINT,
    cache: new InMemoryCache(),
  });

  const getVideos = async (): Promise<Video[]> => {
    return await client
      .query({
        query: gql`
          query {
            videos(first: 300, orderBy: createdAt_DESC) {
              videoId
              videoTitle
              videoDescription
              editorsSelection
              editorsCommentTitle
              editorsComment
              channel {
                channelId
                channelName
              }
              tags {
                id
              }
            }
          }
        `,
      })
      .then((res) => res.data.videos);
  };

  const getTags = async (): Promise<Tag[]> => {
    return await client
      .query({
        query: gql`
          query {
            tags {
              id
              name
              description
              image {
                url
              }
              tagColor {
                hex
              }
              forTodaysTag
              pickup
            }
          }
        `,
      })
      .then((res) => res.data.tags);
  };

  const getNewsCovers = async (): Promise<NewsCover[]> => {
    return await client
      .query({
        query: gql`
          query {
            newsCovers {
              coverImage {
                url(transformation: { image: { resize: { width: 200 } } })
              }
              siteUrl
            }
          }
        `,
      })
      .then((res) => res.data.newsCovers);
  };

  return {
    getVideos,
    getTags,
    getNewsCovers,
  };
};
