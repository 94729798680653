<template>
  <div class="text-sm px-3 mt-6">
    <div
      v-if="video.editorsComment"
      class="relative z-10 border-2 border-snowblue p-4 pt-3"
    >
      <span class="absolute z-20 -top-4 -left-1.5"
        ><img src="/assets/images/hdg_pickup.svg" alt=""
      /></span>
      <span
        class="absolute -top-4 -left-2 z-10 w-24 h-11 bg-snowgray-dark"
      ></span>
      <div class="relative z-30">
        <h3 v-if="video.editorsCommentTitle" class="text-base font-yusei">
          {{ video.editorsCommentTitle }}
        </h3>
        <p class="text-xs leading-relaxed mt-1">{{ video.editorsComment }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Video } from "@/types/graphcms";

export default defineComponent({
  props: {
    video: {
      type: Object as PropType<Video>,
      required: true,
    },
  },
});
</script>
