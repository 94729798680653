import { createApp } from "vue";
import app from "./app.vue";
import router from "./router";
import VueGtag from "vue-gtag-next";
import "@/assets/tailwind.css";

createApp(app)
  .use(VueGtag, {
    property: {
      id: "G-7HVERGR1E7",
    },
  })
  .use(router)
  .mount("#app");
